<template>
    <div class="">
        <div class="flex justify-space-between">
            <div class="box self-flex-end"><h2 class="mv-0">Event</h2></div>
            <div class="box"><el-button @click="$onCommandParams('listOfEvents')">BACK</el-button></div>
        </div>
        <el-divider class="mv-10 o-020"></el-divider>
        <div class="flex column gaps">
          <div class="box grow flex">
            <div class="name box grow flex column justify-center p-10">
              <div class="fullname fs-18 pb-5"><strong>{{currentEvent.name}}</strong></div>
              <div class="fullname fs-14 secondary-text"><strong>{{dateFormat(currentEvent.date)}}</strong></div>
              <div class="fullname fs-14 secondary-text"><strong>{{currentEvent.persons}} persons</strong></div>
            </div>
            <div class="box p-10 rigth flex gaps">
              <div class="box">
                <div class="fullname fs-14 info-text"><strong>{{currentEvent.contact.first}} {{currentEvent.contact.surname}}</strong></div>
                <div class="fs-14 secondary-text">{{currentEvent.contact.email}}</div>
                <div class="fs-14 secondary-text">{{currentEvent.contact.phone}}</div>
                <!-- <div class="fs-14 secondary-text">
                  <el-button type="text" size="small">
                    <a href="http://localhost:3001/uploads/585d900e237805fb9e1a7e108c6c184e">File</a>
                  </el-button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="box flex gaps">
            <div class="box left center" style="min-width: 85px;">
                <h4 class="mt-10 mb-5 o-050">COST</h4>
            </div>
            <div class="box">
                <el-input-number :controls="false" v-model="currentEvent.cost" :disabled="true"></el-input-number>
            </div>
          </div>
          <div class="box flex gaps">
            <div class="box left center" style="min-width: 85px;">
                <h4 class="mt-10 mb-5 o-050">PREPAID</h4>
            </div>
            <div class="box">
                <el-input-number :controls="false" v-model="currentEvent.prepaid" :disabled="true"></el-input-number>
            </div>
          </div>
          <div class="box flex gaps">
            <div class="box left center" style="min-width: 85px;">
                <h4 class="mt-10 mb-5 o-050">AMOUNT</h4>
            </div>
            <div class="box">
                <el-input-number @change="onChangeAmoun($event)" :controls="false" v-model="amount" :disabled="false"></el-input-number>
            </div>
          </div>
          <div class="box">
            <p class="pl-20">{{currentEvent.comment}}</p>
          </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'SaleEvents',

  props: {
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      amount: 0
    }
  },
  computed: {
    ...mapState({
      // basket: state => state.basket.basket,
      events: state => state.event.events
    }),
    ...mapGetters([
      'getEventById'
    ]),
    currentEvent () {
      let event = this.getEventById[this.$route.params.id]
      return event || {
        contact: {
          first: '',
          surname: '',
          mail: '',
          phone: ''
        },
        cost: 0,
        prepaid: 0,
        date: 0,
        persons: 0
      }
    },
    currentBusketItem () {
      let one = this.basket.find(b => b.category === 'Events' && b.eventId === this.$route.params.id)
      return one !== undefined ? one : false
    }
  },
  methods: {
    ...mapActions(['editBasketItem']),
    onChangeAmoun (e) {
      if (this.currentBusketItem) {
        this.editBasketItem({
          basketId: this.currentBusketItem.basketId,
          content: {
            rate: e,
            times: e > 0 ? 1 : 0
          }
        })
      }
    },
    dateFormat (d) {
      let today = new Date(d)
      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      return today.toLocaleString('en-GB', options)
    }
  },
  mounted () {
    if (!this.getEventById[this.$route.params.id]) {
      this.$onCommandParams('home')
    }
    this.amount = this.currentEvent.cost - this.currentEvent.prepaid
  }
}
</script>
